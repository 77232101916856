import React from 'react';

import { getHoverOverItems } from '../../../../sharedModules/getHoverOverItems';
import shouldDisplayUpfrontPrice from '../../../modules/shouldDisplayUpfrontPrice';
import { useHawkWidgetContext } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import { HoverOver } from '../../GenericComponents/HoverOver';
import Switcher from '../../GenericComponents/Switcher/Switcher';
import { UpfrontPrice } from '../../GenericComponents/UpfrontPrice';
import ViewAt from '../../GenericComponents/ViewAt/ViewAt';
import itemStyles from '../Main/styles/simple-items.css';

import { getLabel } from './getLabel';
import { getValue } from './getValue';

const SimpleContractsItems: React.FC = () => {
  const {
    selectedSwitcherItem,
    scrollLeft,
    scrollRight,
    translate,
    genericSharedComponents: { GridItems, Button, DealImage, MainPrice, PriceNote },
  } = useHawkWidgetContext();

  let { switcherItems } = useHawkWidgetContext();
  switcherItems = switcherItems || [];

  return (
    <GridItems
      className={{
        gridItems: itemStyles['grid-items'],
        gridItemBlock: itemStyles['gridItemsBlock'],
        gridItem: itemStyles['gridItem'],
      }}
      widgetName="simple"
    >
      {(deal): JSX.Element => {
        const hoverItems = getHoverOverItems(deal);
        return (
          <>
            <DealImage
              key="merchant"
              deal={deal}
              priority={['network', 'merchant']}
              className="simple-image"
              width={70}
              height={30}
            />
            <React.Fragment key="allowance">
              <Switcher
                className="simple"
                scrollLeft={scrollLeft}
                scrollRight={scrollRight}
                selectedIndex={switcherItems.findIndex((item) => item === selectedSwitcherItem)}
                items={switcherItems.map((item) => ({
                  type: item,
                  label: getLabel(item, deal, translate),
                  value: getValue(item, deal, translate),
                }))}
              />
              {hoverItems.length > 0 && <HoverOver items={hoverItems} />}
            </React.Fragment>
            <React.Fragment key="price">
              {shouldDisplayUpfrontPrice(deal) && <UpfrontPrice deal={deal} className="above" />}
              <MainPrice deal={deal} />
              <PriceNote deal={deal} />
            </React.Fragment>
            <React.Fragment key="link">
              <Button deal={deal} />
              <ViewAt deal={deal} />
            </React.Fragment>
          </>
        );
      }}
    </GridItems>
  );
};

export default SimpleContractsItems;
