import { getMinutesDisplayValue } from '../../../modules/getMinutesDisplay';
import getTextsValue from '../../../modules/getTextsValue';
import type { Deal } from '../../../types/Deal';
import type { Translate } from '../../../types/Localiser';
import formatPhoneAmount from '../../../utils/formatPhoneAmount';

export const getValue = (item: string, deal: Deal, translate: Translate): string | number => {
  switch (item) {
    case 'data':
      return formatPhoneAmount(deal?.contract?.data, translate);
    case 'texts':
      return getTextsValue(deal, translate);
    case 'minutes':
      return getMinutesDisplayValue(deal, translate);
    default:
      return '';
  }
};
