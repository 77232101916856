import { getMinutesDisplayLabel } from '../../../modules/getMinutesDisplay';
import type { Deal } from '../../../types/Deal';
import type { Translate } from '../../../types/Localiser';

export const getLabel = (item: string, deal: Deal, translate: Translate): string => {
  switch (item) {
    case 'minutes':
      return getMinutesDisplayLabel(deal, translate);
    default:
      return item;
  }
};
