import React from 'react';

import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import SimpleFooter from '../Main/SimpleFooter';

import SimpleContractsItems from './SimpleContractsItems';

const SimpleContracts: React.FC = () => (
  <Consumer>
    {(value): JSX.Element => {
      const {
        loading,
        widgetTitle,
        genericSharedComponents: { HawkTabs, Spinner, Title },
      } = value;

      return (
        <>
          {loading && <Spinner />}
          <Title
            {...{
              title: widgetTitle,
              className: 'simple',
            }}
          />
          <HawkTabs />
          <SimpleContractsItems />
          <SimpleFooter />
        </>
      );
    }}
  </Consumer>
);

export default SimpleContracts;
