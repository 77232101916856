import React from 'react';

import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import SimpleSubscriptionsItems from './SimpleSubscriptionsItems';

const SimpleSubscriptions: React.FC = () => (
  <Consumer>
    {(value): JSX.Element | null => {
      const {
        widgetTitle,
        genericSharedComponents: { Title },
      } = value;
      let { dealData } = value;
      dealData = dealData || {};

      if (dealData && dealData.deals && dealData.deals.length > 0) {
        return (
          <>
            <Title
              {...{
                title: widgetTitle,
                className: 'simple',
              }}
            />
            <SimpleSubscriptionsItems />
          </>
        );
      }
      return null;
    }}
  </Consumer>
);

export default SimpleSubscriptions;
