import React, { useContext } from 'react';

import { Context } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import itemStyles from '../Main/styles/simple-items.css';

const SimpleRetailItems: React.FC = () => {
  const {
    genericSharedComponents: { GridItems, Button, DealImage, MainPrice },
  } = useContext(Context);

  return (
    <GridItems
      className={{
        gridItems: itemStyles['grid-items'],
        gridItemBlock: itemStyles['gridItemsBlock'],
        gridItem: itemStyles['gridItem'],
      }}
      widgetName="simple"
    >
      {(deal): JSX.Element => (
        <>
          <DealImage key="image" deal={deal} />
          <DealImage
            key="merchant"
            deal={deal}
            priority={['merchant']}
            className="simple-image"
            width={70}
            height={30}
          />
          <MainPrice key="main" deal={deal} />
          <Button key="link" deal={deal} className="simple" />
        </>
      )}
    </GridItems>
  );
};

export default SimpleRetailItems;
