import React from 'react';

import { useHawkWidgetContext } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import itemStyles from '../Main/styles/simple-items.css';

const SimpleSubscriptionsItems: React.FC = () => {
  const {
    genericSharedComponents: { GridItems, Button, DealImage, DisplayName, MainPrice },
  } = useHawkWidgetContext();

  return (
    <GridItems
      className={{
        gridItems: itemStyles['grid-items'],
        gridItemBlock: itemStyles['gridItemsBlock'],
        gridItem: itemStyles['gridItem'],
      }}
      widgetName="simple"
    >
      {(deal): JSX.Element => (
        <>
          <DealImage
            key="merchant"
            deal={deal}
            priority={['network', 'merchant']}
            className="simple-image"
          />
          <DisplayName key="name" deal={deal} />
          <MainPrice key="price" deal={deal} />
          <Button key="link" deal={deal} />
        </>
      )}
    </GridItems>
  );
};

export default SimpleSubscriptionsItems;
