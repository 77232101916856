import React from 'react';

import { getBaseCCUrl } from '../../../modules/getCCUrl';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import ModelCodeReview from '../../GenericComponents/ModelCodeReview/ModelCodeReview';

import styles from './styles/simple-footer.css';

const SimpleFooter: React.FC = () => (
  <Consumer>
    {(value): JSX.Element => {
      const {
        reviews,
        showReviewsPosition,
        site,
        platform,
        language,
        translate,
        url,
        genericSharedComponents: { AllDeals },
      } = value;
      let { dealData, data, activeTab, params } = value;
      data = data || {};
      dealData = dealData || {};
      activeTab = activeTab || {};
      params = params || {};

      return (
        <div className={styles.container}>
          <div className={styles.reviews}>
            {activeTab?.category === 'retail' &&
              ['default', 'bottom'].indexOf(showReviewsPosition) >= 0 &&
              (reviews || []).map((review) => {
                return (
                  <React.Fragment key={review.code_name}>
                    <ModelCodeReview
                      {...{
                        review,
                        className: 'review-widget-simple',
                        asinLabelSuffix: translate('reviewSimpleFooterAmazonReviewsLabel'),
                        key: review.code_value,
                      }}
                    />
                    {getBaseCCUrl(site, platform, language, url) && (
                      <div className={styles.divider}>|</div>
                    )}
                  </React.Fragment>
                );
              })}
          </div>
          <div className={styles.link}>
            <AllDeals
              {...{
                params: {
                  productTypes: params.filter_product_types,
                  modelName: data.title,
                },
              }}
            >
              <span className={styles.label}>
                {translate('reviewSimpleFooterLabel', [String(dealData.totalDeals)])}
              </span>
            </AllDeals>
          </div>
        </div>
      );
    }}
  </Consumer>
);

export default SimpleFooter;
