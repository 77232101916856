import React from 'react';

import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import SimpleFooter from '../Main/SimpleFooter';

import SimpleRetailItems from './SimpleRetailItems';

const SimpleRetail: React.FC = () => (
  <Consumer>
    {(value): JSX.Element => {
      const {
        loading,
        widgetTitle,
        genericSharedComponents: { HawkTabs, Spinner, Title },
      } = value;

      return (
        <>
          {loading && <Spinner />}
          <Title
            {...{
              title: widgetTitle,
              className: 'simple',
            }}
          />
          <HawkTabs />
          <SimpleRetailItems />
          <SimpleFooter />
        </>
      );
    }}
  </Consumer>
);

export default SimpleRetail;
